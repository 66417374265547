import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByProperty',
    standalone: true
})
export class FilterByPropertyPipe implements PipeTransform {
    transform(items: any[], field : string, value : any, invert: boolean = false): any[] {
      if (!items) return [];
        if (value === undefined || value.length == 0)
            return items;
      

      if (typeof value !== 'string') {
        return items.filter(it => it[field] !== undefined
          && ((!invert && it[field] === value)
            || (invert && it[field] !== value))
        );
      }
      else if (invert) {
        return items.filter(it => it[field] && it[field].toLowerCase().indexOf(value.toLowerCase()) == -1);
      }
      else
      {
        return items.filter(it => it[field] && it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1);
      }
    }
}
