import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { SnackbarService } from '@app/services/snackbar.service';
import { catchError, tap } from 'rxjs/operators';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackbar: SnackbarService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          if (response.body !== undefined && response.body !== null && response.body.success === false) {
            throw new Error(response.body.error);
          }
        }
      }),
      catchError(error => {
        // Handle throw error
        let message;
        if (error instanceof Error) {
          message = error.message;
        }
        else if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ProgressEvent) {
            message = 'There seems to be a problem with your internet connection!';
          }
          else {
            message = error.error;
          }
        }

        if (message.indexOf('JWT Authentication error:') !== -1) {
          // these errors are handled by the token interceptor
          if (message.indexOf('Expired token') !== -1) {
            return throwError(message);
          }
          else {
            // log out message
            this.snackbar.show('You have been logged out due to inactivity!');
            return throwError(message);
          }
        }

        if (typeof message === 'object') {
          if ('error' in message) {
            message = (message as { error: { message: string } }).error;
          }
          if ('message' in message) {
            this.snackbar.show((message as { message: string }).message);
          }
          else {
            this.snackbar.show('An unknown error has occurred!');
          }
        }
        else {
          this.snackbar.show(message);
        }
        return throwError(message);
      })
    )
  }
}
