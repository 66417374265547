import { Component, OnInit, OnDestroy } from '@angular/core';
import { SnackbarService, SnackbarType } from '@app/services/snackbar.service';
import { Subscription } from 'rxjs';
import { trigger, transition, animate, style } from '@angular/animations';
import { delay } from 'rxjs/operators';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'tlk-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    animations: [
        trigger('state', [
            transition(':enter', [
                style({ right: '-100px', transform: 'scale(0.6)' }),
                animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({
                    transform: 'scale(1)',
                    opacity: 1,
                    right: '3rem'
                }))
            ]),
            transition(':leave', [
                animate('150ms cubic-bezier(0.4, 0, 1, 1)', style({
                    transform: 'scale(0.8)',
                    opacity: 0,
                    right: '-100px'
                }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class SnackbarComponent implements OnInit, OnDestroy {
  show: boolean = false;
  message: string = '';
  type: SnackbarType = SnackbarType.Error;
  subscription: Subscription;
  constructor(private snackbar: SnackbarService) { }

  ngOnInit(): void {
    let closeTimeout = null;

    this.subscription = this.snackbar.show$.subscribe(state => {
      // if already open
      if (this.show) {
        this.show = false;
        if (closeTimeout !== null) {
          clearTimeout(closeTimeout);
          closeTimeout = null;
        }
        setTimeout(() => this.snackbar.show$.next(state), 150);
        return;
      }
      
      this.type = state.type;
      this.message = state.message;
      this.show = true;
      

      closeTimeout = setTimeout(() => {
        this.show = false;       
      }, 6000);
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
