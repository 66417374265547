import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum SnackbarType {
  Error = 'error',
  Success = 'success',
  Info = 'info'
}

export interface SnackbarData {
  message: string;
  type: SnackbarType;
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  show$: Subject<SnackbarData> = new Subject<SnackbarData>();
  constructor() { }

  show(message: string, type: SnackbarType = SnackbarType.Error) {
    this.show$.next({
      message,
      type
    });
  }
}
