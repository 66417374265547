import { Injectable } from '@angular/core';
import { StorageKey } from '@app/enums/storage-key.enum';
import { Credential } from '@app/models/credential.model';
import { Token } from '@app/models/token.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map, share } from 'rxjs/operators';
import { PlayerService } from './player.service';
import { UserService } from './user.service';
import { User } from '@app/models/user.model';
import { Player } from '@app/models/player.model';
import { Settings } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _token: Token = null;
  private loginStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private player: PlayerService,
    private user: UserService
  ) { }

  public get token(): Token {
    if (this._token === null) {
      // check in session then in local storage
      let data = sessionStorage.getItem(StorageKey.AuthInfo);
      if (data === null) {
        data = localStorage.getItem(StorageKey.AuthInfo);
      }
      // decode JSON information if data was found in storage
      if (data !== null) {
        this._token = <Token>JSON.parse(data);
      }
    }
    return this._token;
  }

  private isTokenPresentInLocalOrSession(): 'local' | 'session' | null {
    let data = sessionStorage.getItem(StorageKey.AuthInfo);
    if (data !== null) {
      return 'session';
    }

    data = localStorage.getItem(StorageKey.AuthInfo);
    if (data !== null) {
      return 'local';
    }

    return null;
  }

  public isLoggedIn() {
    return this.token !== null;
  }

  public getLoginStatus(): Observable<boolean> {
    return this.loginStatus.pipe(
      share()
    );
  }

  public logout(): Observable<void> {
    return this.http.post<void>('/json.php', {
      page: 'user',
      request: 'deleteAuthToken'
    }, {
      headers: { 'Authorization': `Delete ${this._token.refresh}` }
    }).pipe(
      tap(() => {
        this.deleteTokens();
      })
    );
  }

  public logoutOfAllDevices(): Observable<void> {
    return this.http.post<void>('/json.php', {
      page: 'user',
      request: 'deleteAllAuthTokens'
    }).pipe(
      tap(() => {
        this.deleteTokens();
      })
    );
  }

  public deleteTokens(): void {
    this.loginStatus.next(false);
    this._token = null;
    sessionStorage.removeItem(StorageKey.AuthInfo);
    localStorage.removeItem(StorageKey.AuthInfo);
  }

  public login(credential: Credential, rememberMe: boolean): Observable<Token> {
    return this.http.post<Token>('/json.php', {
      page: 'user',
      request: 'getAuthToken',
      params: { rememberMe }
    }, {
      headers: { 'Authorization': `Basic ${btoa(credential.email + ':' + credential.password)}` }
    }).pipe(
      tap(data => {
        this.loginStatus.next(true);
        
        if (rememberMe) {
          localStorage.setItem(StorageKey.AuthInfo, JSON.stringify(data));
        }
        else {
          sessionStorage.setItem(StorageKey.AuthInfo, JSON.stringify(data));
        }        
        this._token = data;
      })
    );
  }

  public refreshToken(): Observable<Token> {
    return this.http.post<Token>('/json.php', {
      page: 'user',
      request: 'refreshTokens'
    }, {
      headers: { 'Authorization': `Refresh ${this._token.refresh}` }
    }).pipe(
      tap(data => {
        if (this.isTokenPresentInLocalOrSession() === 'local') {
          localStorage.setItem(StorageKey.AuthInfo, JSON.stringify(data));
        }
        else {
          sessionStorage.setItem(StorageKey.AuthInfo, JSON.stringify(data));
        }        
        this._token = data;
      })
    );
  }

  private getUserAndPlayerData(): Observable<{
    user: User,
    player: Player
  }> {
    return this.http.post<any>('/json.php', {
      page: 'user',
      request: 'getData'
    }, { headers: { 'No-Loader': 'true' }}).pipe(
      map(data => {
        if (!data) {
          console.error('Unexpected error, was unable to load player data.');
          return {
            user: null,
            player: null
          }
        }

        console.log("User/Player loaded");

        let user = Object.assign(new User(), data as User);
        user.id = data.userId;

        let player = Object.assign(new Player(), data as Player);
        player.id = data.playerId;

        return {
          user: user,
          player: player
        };
      })
    );
  }

  public loadUserAndPlayerDataIfLoggedIn(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (this.isLoggedIn()) {
        this.getUserAndPlayerData().subscribe(data => {
          if (data.player && data.user) {
            this.player.setData(data.player);
            this.user.setData(data.user);

            // set default timezone as well
            if (data.user.timezone != null && data.user.timezone.length > 0)
              Settings.defaultZone = data.user.timezone;
          }
          
          resolve();
        }, error => reject(error));

        this.loginStatus.next(true);
      }
      else {
        this.loginStatus.next(false);
        resolve();
      }
    });
  }

  public register(username: string, email: string): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'user',
      request: 'Register',
      params: {
        username,
        email
      }
    });
  }

  public checkActivationCode(code: string): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'user',
      request: 'CheckActivationCode',
      params: { code }
    });
  }

  public checkPasswordCode(code: string): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'user',
      request: 'CheckPasswordCode',
      params: { code }
    });
  }

  public requestPasswordReset(email: string): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'user',
      request: 'ForgotPassword',
      params: { email }
    });
  }

  public setPassword(code: string, type: string, password: string): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'user',
      request: 'ChangePassword',
      params: {
        code,
        type,
        password
      }
    });
  }
}
