import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {AuthService} from '@app/services/auth.service';
import {PlayerService} from '@app/services/player.service';
import {Subscription} from 'rxjs';
import {Player} from '@app/models/player.model';
import {PageService} from '@app/services/page.service';
import {ConditionalMenu} from '@app/shared/components/nav/conditional-menu.enum';
import { User } from '@app/models/user.model';
import { UserService } from '@app/services/user.service';
import { AgeService } from '../../../services/age.service';
import { Age } from '@app/models/age.model';
import { SnackbarService, SnackbarType } from '@app/services/snackbar.service';
import { DevCheatsPopup } from '../../popups/dev-cheats/dev-cheats.popup';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { StatsProtectionComponent } from '../stats/protection/component';
import { StatsMovepointsComponent } from '../stats/movepoints/component';
import { StatsAuthorityComponent } from '../stats/authority/component';
import { GoldComponent } from '../gold/gold.component';
import { StatsExperienceComponent } from '../stats/experience/component';
import { UserRankComponent } from '../user-rank/user-rank.component';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { AgeStatisticsComponent } from '../age-statistics/age-statistics.component';
import { SvgComponent } from '../svg/svg.component';
import { NgIf, NgClass, DecimalPipe } from '@angular/common';

@Component({
    selector: 'tlk-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    standalone: true,
    imports: [NgIf, SvgComponent, AgeStatisticsComponent, CountryFlagComponent, UserRankComponent, RouterLink, NgClass, StatsExperienceComponent, GoldComponent, StatsAuthorityComponent, StatsMovepointsComponent, StatsProtectionComponent, SkeletonComponent, MenuGroupComponent, MenuItemComponent, DecimalPipe]
})
export class NavComponent implements OnInit, OnDestroy {
  ConditionalMenu = ConditionalMenu;
  stats: any = {};
  //statsLoaded = false;
  public age: Age = null;

  private _player: Player = null;

  public get player(): Player {
    return this._player;
  }
  public set player(value: Player) {
    let loadStats = false;

    if (this._player == null && value != null)
      loadStats = true;

    this._player = value;

    //if (loadStats)
    //  this.GetPlayerStats();
  }

  user: User = null;

  playerSubscription: Subscription;
  userSubscription: Subscription;
  statsSubscription: Subscription;
  ageSubscription: Subscription;

  constructor(
    private router: Router,
    public auth: AuthService,
    private AgeService: AgeService,
    private playerService: PlayerService,
    private userService: UserService,
    private ModalService: NgbModal,
    public page: PageService,
    private snackbar: SnackbarService
  ) { }

  ngOnInit(): void {
    this.playerSubscription = this.playerService.getData().subscribe(data => {
      this.player = data;
    });
    this.userSubscription = this.userService.getData().subscribe(data => {
      this.user = data;
    });
    this.ageSubscription = this.AgeService.Age.subscribe(data => {
      this.age = data;
    });
    this.statsSubscription = this.playerService.getRanking().subscribe(data => {
      this.stats = data;
      //this.statsLoaded = true;
    });
  }

  //GetPlayerStats() {
  //  this.statsSubscription = this.playerService.getRanking().subscribe(data => {
  //    this.stats = data;
  //    this.statsLoaded = true;
  //  });
  //}

  ngOnDestroy(): void {
    this.playerSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.statsSubscription.unsubscribe();
    this.ageSubscription.unsubscribe();
  }

  conditionalMenu(): ConditionalMenu {
    if (!this.auth.isLoggedIn()) {
      return ConditionalMenu.ACCOUNT;
    } else if (this.player == null || this.player.nation == null || this.player.nation == null || this.player.nation.Name == 'no country') {
      return ConditionalMenu.NO_COUNTRY;
    } else {
      return ConditionalMenu.COUNTRY;
    }
  }

  ShowCheats() {
    const modalRef = this.ModalService.open(DevCheatsPopup, { size: 'xl', fullscreen: 'xl', scrollable: true });
  }
}
