import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICity } from '@app/interfaces/city.interface';
import { City } from '@app/models/city.model';
import { StorageKey } from '@app/enums/storage-key.enum';
import { ICityService } from './icity.service';
import { ServiceNotReadyError } from '@app/exceptions/service-not-ready.error';
import { map, tap } from 'rxjs/operators';
import { ILandfill } from '../interfaces/landfill.interface';

@Injectable({
  providedIn: 'root'
})
export class CityService extends ICityService {
  private cities: ICity[] = null;
  private landfill: ILandfill[] = null;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  public setStaticData(cities: ICity[], landfill: ILandfill[]) {
    this.cities = cities;
    this.landfill = landfill;
    this.fetchCitiesDynamicData().toPromise();
  }

  public fetchCitiesDynamicData(): Observable<ICity[]> {
    return this.http.post<any[]>('/json.php', {
      page: 'general',
      request: 'getCitiesDynamicData'
    }).pipe(map((data: any[]) => {
      return data.map(dynamicCity =>
        // MERGE STATIC WITH DYNAMIC DATA
        Object.assign(new City(), {
          ...this.cities[this.cities.findIndex(x => x.id === dynamicCity.id)],
          ...dynamicCity
        } as City)
      )
    }))
      .pipe(tap(cities =>
        // STORE RESULT FOR LATER USE, BUT ALSO RETURN IT
        this.cities = cities)
      );
  }

  public getCityById(id: number): ICity {
    if (this.cities === null)
      throw new ServiceNotReadyError("City service not ready yet");

    return this.cities[this.cities.findIndex(x => x.id === id)];
  }

  public getCities(): ICity[] {
    if (this.cities === null)
      throw new ServiceNotReadyError("City service not ready yet");

    return this.cities;
  }

  public getLandfill(): ILandfill[] {
    if (this.landfill === null)
      throw new ServiceNotReadyError("City service not ready yet");

    return this.landfill;
  }
}
