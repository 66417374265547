<div id="nav">
  <div class="MenuTiledBackground">
    <div>
      <div class="playerstats" *ngIf="player != null">
        <div *ngIf="page.isDevelopment" class="d-flex justify-content-end">
          <button class="RedButton" title="Cheats" (click)="ShowCheats()" style="display:block; margin: 2px;">
            <tlk-svg fill="accent" url="/assets/fontawesome/cogs-solid.svg" class="userbaricon game-icon"></tlk-svg>
          </button>
        </div>
        <div *ngIf="!page.isDesktopView">
          <tlk-age-statistics></tlk-age-statistics>
          <div class="userinfo">
            <tlk-country-flag [Nation]="player.nation"></tlk-country-flag>
            <tlk-user-rank [Rank]="player.rank" [Female]="player.female == 1"></tlk-user-rank>
            <span style="font-weight: 500; font-size: 1.25rem;">{{ player.rank.name[player.female ? 'female' : 'male'] }} </span>
            <a [routerLink]="['/user/profile/' + player.name]" class="accent" style="text-decoration: none;">
              <span style="font-weight: 500; font-size: 1.25rem;">{{ player.name }}</span>
            </a>
          </div>
        </div>
        <div class="row pt-3">
          <div [ngClass]="{'col-6': !page.isDesktopView, 'col-12': page.isDesktopView }">
            <div>
              XP: <tlk-experience [value]="player.xp" [total]="player.rank.experience"></tlk-experience>
            </div>
            <div>
              Gold: <tlk-gold [Amount]="player.gold"></tlk-gold>
            </div>
            <div>
              Authority: <tlk-authority [value]="player.authority" [total]=""></tlk-authority>
            </div>
            <div>
              MPs: <tlk-movepoints [value]="player.movePoints"></tlk-movepoints>
            </div>
            <div>
              Protection: <tlk-protection [value]="player.pvpProtection"></tlk-protection>
            </div>
          </div>
          <div [ngClass]="{'col-6': !page.isDesktopView, 'col-12': page.isDesktopView }" [skeleton]="stats != null">
            <ng-container *ngIf="stats != null">
              <div>
                Rank: #{{ ((stats.rankingPlace | number) || 'TBA') + ' (' + ((stats.rankingScore | number) || 'TBA') + ')' }}
              </div>
              <div>
                PvP: #{{ (stats.pvpPlace | number) + ' (' + (stats.pvpScore === 'Unranked' ? stats.pvpScore : (stats.pvpScore | number)) + ')' }}
              </div>
              <div>
                SF: #{{ (stats.sfPlace | number) + ' (' + (stats.sfScore | number) + ')' }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!--NOT LOGGED IN-->
      <tlk-menu-group title="{{ conditionalMenu().valueOf() }}">
        <tlk-menu-item routerLinkUrl="/"
                       *ngIf="conditionalMenu() === ConditionalMenu.ACCOUNT">Login</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/registration"
                       *ngIf="conditionalMenu() === ConditionalMenu.ACCOUNT">New Player</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/telegram"
                       *ngIf="conditionalMenu() === ConditionalMenu.NO_COUNTRY || conditionalMenu() === ConditionalMenu.COUNTRY">Chat</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/nation/players"
                       *ngIf="conditionalMenu() === ConditionalMenu.COUNTRY">Pla<u>y</u>ers</tlk-menu-item>        
        <tlk-menu-item routerLinkUrl="/battle/pvp-results"
                       *ngIf="conditionalMenu() === ConditionalMenu.COUNTRY">PvP Results</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/nation/join"
                       *ngIf="conditionalMenu() === ConditionalMenu.NO_COUNTRY">Pick a Country</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/city/armory"
                       *ngIf="conditionalMenu() === ConditionalMenu.NO_COUNTRY"><u>A</u>rmory</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/city/encampment"
                       *ngIf="conditionalMenu() === ConditionalMenu.NO_COUNTRY"><u>E</u>ncampment</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/city/tournament"
                       *ngIf="conditionalMenu() === ConditionalMenu.NO_COUNTRY"><u>T</u>ournament</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/tutorial"
                       *ngIf="conditionalMenu() === ConditionalMenu.NO_COUNTRY || conditionalMenu() === ConditionalMenu.COUNTRY">Tutorial</tlk-menu-item>
      </tlk-menu-group>
      <tlk-menu-group title="Age">
        <tlk-menu-item routerLinkUrl="/neptune"
                       *ngIf="age.event == 'NeptunesWrath'">Neptune's Wrath</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/pandemic"
                       *ngIf="age.event == 'Pandemic'">Pandemic</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/map"><u>M</u>ap</tlk-menu-item>        
        <tlk-menu-item routerLinkUrl="/nation/rankings">Countries</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/wars"><u>W</u>ars</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/user/rankings"><u>R</u>anking</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/stats">Stats</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/battle/city-battles">City Battles</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/map-voting">Map Voting</tlk-menu-item>
      </tlk-menu-group>
      <tlk-menu-group title="Resources">        
        <tlk-menu-item routerLinkUrl="/misc/reference-guide">Reference Guide</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/faq">FAQ / Help</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/user/mentor">Mentor Help</tlk-menu-item>        
        <tlk-menu-item routerLinkUrl="/forum">Forum</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/nobility">Nobility</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/hof">Hall of Fame</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/change-log">Change Log</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/misc/support">Support</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/admin" *ngIf="user.adminPermissionsCount > 0">Admin</tlk-menu-item>
      </tlk-menu-group>
      <tlk-menu-group title="Settings" *ngIf="auth.isLoggedIn()">
        <tlk-menu-item routerLinkUrl="/user/settings">Profile</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/user/buddies">Buddies</tlk-menu-item>
        <tlk-menu-item routerLinkUrl="/logout">Logout</tlk-menu-item>
      </tlk-menu-group>
    </div>
  </div>
  <div class="MenuFooter">
    <p>&nbsp;</p>
  </div>
</div>
